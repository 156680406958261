$background: #f3f1ee; /* #92c7cf */
$FC: #000000;
$SC: #ffffff;
$TC: #332b25; /* 332b25 */
$UC: #453324; /*  */
$logo-color: #bf253a;

$block-width: calc(100vw - 50px);
$block-width-second: calc(100vw - 1px);
$max-width: 1000px;
$block-height: 500px;
$lovely-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1),
  0 0 3px 0 rgba(0, 0, 0, 0.1);

$first-endpoint: 1024px;
$second-endpoint: 430px;
$third-endpoint: 920px;
$fourth-endpoint: 825px;
$fives-endpoint: 1225px;

$padding: 2%;

$svg-size: 30px;

@import './All.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 20px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

body {
  background-color: beige;
  /* 
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center; */
  /*   align-items: center; */
  .App {
    text-align: center;
    background-color: white;
    max-width: $max-width;
    width: auto;
    margin: 0 auto;
    height: fit-content;
    min-height: 100vh;
    position: relative;

    &-spotContent {
      position: relative;
      .topImage {
        height: 200px;
        width: 100%;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .titleblock {
        text-align: left;
        padding: 0;
        h3 {
          font-size: 40px;
          font-weight: bold;
          font-family: 'Ms Madi', cursive;
          font-weight: 400;
          margin: 0 2%;
          font-family: 'Dancing Script', cursive;
          font-optical-sizing: auto;
          font-style: normal;
          color: yellow;
          text-shadow: 1px 1px 1px black;
        }
        p {
          font-size: inherit;
          margin: 0 2%;
        }
      }

      .list {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2%;
        width: 100%;
        margin: 10px auto;
        a {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2%;
          background-color: $TC;
          color: white;
          padding: $padding;
          border-radius: 25px;
          transition: all 0.3s;
          div {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            p {
              margin-left: 10px;
            }
          }
          &:last-child {
            margin-bottom: 0;
            align-self: flex-end;
          }
          &:hover {
            background-color: $UC;
            transition: all 0.3s;
          }
          img {
            width: 36px;
            height: auto;

            &:first-child {
              @media (max-width: 600px) {
                margin-left: 5px;
              }
            }
          }
        }
      }

      .about {
        display: flex;
        flex-direction: column;
        padding: $padding;

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 2%;
          &:last-child {
            margin-bottom: 0;
          }

          img {
            width: 60px;
            height: auto;
            color: black;
            border-radius: 10px;
            background-color: $TC;
          }

          div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 10px;
          }
        }
      }


    }
  }

  hr {
    border-color: rgba(145, 145, 145, 0.2);
  }
}

@import '../variables/variables.scss';

.App {
  .cart-top {
    padding-top: 10px;
    position: relative;
    margin-bottom: 30px;
    h2 {
      font-size: 30px;
      margin: 0 auto;
      padding: 10px auto;
    }
     p {
      font-size: inherit;
      color: rgba($color: $FC, $alpha: 0.5);
     }

    svg.close {
      position: absolute;
      top: 20px;
      right: 10px;
      width: 30px;
      fill: $SC;
      background-color: $TC;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .cart-container {
    position: relative;
    padding: 0 10px 10px;
    .cart-itemBlock {
      display: flex;
      justify-content: space-between;
      padding: 0;
      
      .cart-itemAbout {
        display: flex;
        justify-content: space-between;

        div.img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        .cart-itemLeft {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          height: 100%;
          padding-left: 10px;
          width: fit-content;
          h3 {
            justify-content: left;
            align-content: start;
            align-items: start;
            padding-left: 0;
            background-color: inherit;
            text-align: left;
          }

          .count {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: fit-content;
            svg {
              height: $svg-size;
              width: $svg-size;
              fill: $FC;
              cursor: pointer;
            }
            .current-count {
              margin: auto 5px;
            }
          }
        }
        .cart-itemRight {
          position: absolute;
          right: 10px;
          bottom: 20px;
          background-color: inherit;
          width: max-content;
        }
      }
    }
    hr {
        background-color: inherit;
        margin-top: 10px;
    }
}
.sum1, .sum2, .sum3 {
display: flex;
justify-content: space-between;
padding: 10px;

}
}



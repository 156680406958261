@import '../variables/variables.scss';

.lang {
  display: flex;
  flex-direction: row;
  padding: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover,
  &:hover select,
  &:hover svg {
    background-color: rgb(105, 105, 5); /* Изменяем цвет фона при наведении */
    color: white;
    fill: white;
    transition: all 0.3s;
  }

  select {
    -webkit-appearance: none; /* Safari and Chrome */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Modern browsers */
    padding: 5px;
    font-size: 16px;
    background-color: white;
    border: 0;
    transition: all 0.3s;

    &:focus {
      outline: none; /* Remove the focus outline */
    }
  }
  svg {
    fill: $TC;
    transition: all 0.3s;
    background-color: white;
  }
}

@import '../variables/variables.scss';

.App {
  max-width: $max-width;

  .top-container {
    display: flex;
    position: relative;
    padding: 2%;
    flex-direction: column;
    width: 100%;
    height: auto;
    .logo {
      font-size: 40px;
      font-weight: bold;
      font-family: 'Ms Madi', cursive;
      font-weight: 400;
      margin: 1% auto 2%;
      font-family: 'Dancing Script', cursive;
      font-optical-sizing: auto;
      font-style: normal;
      color: yellow;
      text-shadow: 1px 1px 1px black;
    }
    .searh-input {
      width: 100%;
      height: fit-content;
      padding: 5px;
    }
  }

  .category-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 2% 0;
    height: fit-content;
    overflow-x: auto;
    cursor: pointer;
    scroll-snap-type: x mandatory; /* Позволяет фиксировать элементы при прокрутке */
    -webkit-overflow-scrolling: touch; /* Обеспечивает плавную прокрутку на iOS устройствах */
    /*  padding: 10px 0 10px 10px; /* Добавляет отступы вокруг содержимого */
    /* padding: 10px !important; */
    /* position: sticky;
    top: 0; */
    background-color: white;
    flex-wrap: nowrap;
    z-index: 3;

    &::-webkit-scrollbar {
      display: none; /* скрывает ползунок прокрутки Safari and Chrome */
    }

    .single-block,
    .active {
      min-height: max-content;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 5px;
      border-radius: 10px;
      margin-left: 10px;
      flex: 0 0 auto; /* Элементы не будут растягиваться
      /*  scroll-snap-align: start; */ /* Фиксирует элементы при прокрутке */
      background-color: #f0f0f0; /* Пример фона для видимости */
      max-width: 110px;
      transition: all 0.5s;
      &:last-child {
        margin-right: 10px;
      }

      div {
        width: 100px;
        height: 80px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      h4 {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        word-wrap: break-word; /* Перенос длинных слов */
        white-space: normal; /* Перенос строк при необходимости */
      }
    }
    .active {
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      transition: all 0.5s;
    }
  }

  .subcategory-block {
    display: flex;
    flex-direction: row;
    padding: 2% 2%;
    overflow-x: auto;
    scroll-snap-type: x mandatory; /* Позволяет фиксировать элементы при прокрутке */
    -webkit-overflow-scrolling: touch; /* Обеспечивает плавную прокрутку на iOS устройствах */
    /*  padding: 10px 0 10px 10px; /* Добавляет отступы вокруг содержимого */
    /*  padding: 10px !important; */
    position: sticky;
    top: 0;
    background-color: white;
    cursor: pointer;
    flex-wrap: nowrap;
    z-index: 3;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.288);

    &::-webkit-scrollbar {
      display: none; /* скрывает ползунок прокрутки Safari and Chrome */
    }

    div,
    .active {
      padding: 5px 10px;
      background-color: #f0f0f0;
      color: black;
      transition: all 0.5s;
      margin-right: 10px;
      border-radius: 10px;
    }

    .active {
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      transition: all 0.5s;
    }
  }

  .food-block {
    width: 100%;
    height: fit-content;
    background-color: white;
    padding: 1% 2%;

    .food-g-name {
      width: fit-content;
      height: auto;
      text-align: left;
      direction: ltr;
      margin-bottom: 10px;
    }
    .food-subblock {
      hr {
        margin: 5px 0;
      }

      .food-siblock {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        padding: 5px 0 5px;

        .food-image {
          height: 140px;
          min-width: 140px;
          position: relative;
          overflow: hidden;
          padding: 5px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 10px;
          }
        }
        .food-item {
          direction: ltr;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          width: 100%;

          .food-item2 {
            display: flex;
            flex-direction: column;
          }
          .food-price {
            text-align: left;
            justify-content: end;
            align-content: center;
          }

          div.count-block {
            position: absolute;
            right: 0;
            bottom: 0;
            width: fit-content;
          }
          svg {
            fill: black;
            right: 0;
            bottom: 0;
            cursor: pointer;
            width: 30px;
          }
          div.count {
            display: flex;
            flex-direction: row;
            align-items: center;
            div.current-count {
              margin: auto 5px;
            }
            svg {
              fill: black;
              right: 0;
              bottom: 0;
              cursor: pointer;
              width: $svg-size;
            }
          }
        }
      }
    }
  }
  .buttun-cart {
    width: fit-content;
    padding: 10px 15px;
    border-radius: 20px;
    height: fit-content;
    background-color: $TC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;

    bottom: 30px;
    z-index: 10;
    right: 20px;

    svg {
      height: 40px;
      width: 40px;
      fill: $SC;
    }
    p {
      margin-left: 5px;
      text-decoration: none;
      color: $SC;
    }
  }
}

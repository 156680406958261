@import '../variables/variables.scss';


.socialN {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px auto;

    a {
      width: 60px;
      height: auto;
      /* margin-right: 20px; */
      cursor: pointer;
      padding: 0;
      margin: 0 20px auto 0;
      transition: all 0.1s;

      img {
        width: 60px;
        height: auto;
      }

      &:last-child {
        margin-right: 0;
      }
      &:hover {
        margin-top: -3px;
        transition: all 0.1s;
      }
    }
  }

  .footer-bottom {
    /* margin: 10px auto 30px; */
    min-height: 90px;

    div {
      margin-bottom: 10px;
      &:last-child {
        margin: 20px auto 20px;
      }

      a {
        margin-top: 10px;
        background-color: #99bc85;
        text-decoration: none;
        padding: 10px;
        border-radius: 10px;
        color: $SC;
        font-weight: 800;
        font-size: 16px;
        animation-name: logo;
        animation-duration: 8s;
        animation-iteration-count: infinite;

        @keyframes logo {
          0% {
            background-color: #6d2932;
          }
          20% {
            background-color: #f6d776;
            color: $FC;
          }
          40% {
            background-color: #37b5b6;
            color: $SC;
          }
          60% {
            background-color: black;
            color: $SC;
          }
          80% {
            background-color: #597e52;
          }
          100% {
            background-color: #6d2932;
          }
        }
      }
    }
  }